/** @jsx jsx */
import { Fragment, FC, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_WHITE,
	colors,
	media,
	THEME_GREEN,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
	SLIDE_LAYOUT_DEFAULT,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_2UP,
	SHOWCASE_LAYOUT_4UP,
} from '../../components/content/Showcase'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'
import iconsGif from '../../images/facebook/Icons.gif'

const nextPage = caseStudyLinkData.facebook.next

type Props = {
	accentTheme: string
}

const FacebookPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			graphics: allFile(
				filter: { relativeDirectory: { eq: "facebook/graphics" } }
				sort: { fields: name, order: ASC }
			) {
				nodes {
					name
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		}
	`)

	const graphicsByName = useMemo(() => {
		return (data.graphics.nodes as { name: string }[]).reduce(
			(result, node) => {
				return {
					...result,
					[node.name]: node,
				}
			},
			{}
		)
	}, [data]) as Record<string, any>

	return (
		<Fragment>
			<Helmet>
				<title>Facebook</title>
				<meta content="Facebook Case Study" property="og:title" />
				<meta content="Facebook Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="Expression & identity for 3 billion users."
				/>
				<meta
					name="description"
					content="Expression & identity for 3 billion users."
				/>
				<meta
					content="https://freeassociation.com/share/facebook.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/facebook.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Facebook</h1>
							<p className="--headline">
								Expression & identity for 3 billion users.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Facebook is one of the most-used digital products in the
									world, fueling billions of interactions between millions of
									people every single day. Over the past three years, Free
									Association has been an integrated partner driving a
									systematic approach to creating brand expressions within
									iconography, illustration, data visualization, motion and
									more. Our approach has taken internal teams from production to
									product-led thinking, allowing Facebook to amplify their brand
									in new ways while solving key product challenges along the
									way.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>Motion Design</li>
									<li>Illustration</li>
									<li>User Experience Design</li>
									<li>UI & Visual Design</li>
									<li>Brand Systems & Guidelines</li>
									<li>Art Direction</li>
									<li>Prototyping</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={2540} height={1440} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/media/facebook/Highlight-Reel.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Working alongside Facebook’s Visual System team, we’ve focused
									on elevating key aspects of the product experience through the
									lens of brand, leading design and production for multiple
									releases of expressive and animation-rich features like
									reactions and reshares.
								</p>
							</Text>
						</LayoutIndented>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1920} height={1364} mode={VIDEO_CLIP_AUTOPLAY}>
						<source
							src="/media/facebook/MotionMoji_White-Bigger.mp4"
							type="video/mp4"
						/>
					</VideoLoop>
				</Container>
			</Slide>

			<Slide prefferedTheme={THEME_TAUPE}>
				<div>
					<Container>
						<GatsbyImage
							alt="Facebook"
							fluid={graphicsByName.reactions.childImageSharp.fluid}
						/>
					</Container>
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={1920} height={1080} mode={VIDEO_CLIP_AUTOPLAY}>
						<source
							src="/media/facebook/Reshares-White-1920x1080.mp4"
							type="video/mp4"
						/>
					</VideoLoop>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Beyond expression, we worked closely with internal teams to
									design a system of visual signals to communicate user
									identity, contextualize content, and foster trust in
									communities across high-visibility surfaces on the platform.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Framework">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We conducted an audit and synthesized opportunities to
										elevate signals on the social platform. We then worked with
										a cross-functional product team to craft a strategic
										framework to organize the many levels of user identity into
										a hierarchical set of tiers.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Verification">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We worked with the Facebook Integrity team to codify the
										language of verification on the platform, comprising a new
										“verified” badge design, and a secondary set of authenticity
										signals to support surface-specific use cases.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Identifiers">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We designed a multi-level iconography system, and
										collaborated on label color and display logic for a system
										of identifiers including community roles and content
										signals.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Awards">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We designed a visual system for celebrating contribution and
										fostering positive behavior within communities, integrated
										into key surfaces including Live, Watch, Groups,
										Marketplace, and Feed.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						style={{
							maxWidth: 'none',
						}}
						objectFit="cover"
						fluidImageData={graphicsByName.cards.childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_4UP}
						images={[
							graphicsByName.facebook_phones_01_01.childImageSharp.fluid,
							graphicsByName.facebook_phones_01_02.childImageSharp.fluid,
							graphicsByName.facebook_phones_01_03.childImageSharp.fluid,
							graphicsByName.facebook_phones_01_04.childImageSharp.fluid,
						]}
						chrome={false}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={THEME_TAUPE} layout={SLIDE_LAYOUT_DEFAULT}>
				<div>
					<Container>
						<img
							src={iconsGif}
							alt="Facebook"
							css={{
								width: '100%',
								display: 'block',
							}}
						/>
					</Container>
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						style={{
							maxWidth: 'none',
						}}
						objectFit="cover"
						fluidImageData={graphicsByName['dark-mode'].childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="40rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_2UP}
						images={[
							graphicsByName.facebook_phones_02_01.childImageSharp.fluid,
							graphicsByName.facebook_phones_02_02.childImageSharp.fluid,
						]}
						chrome={false}
					/>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						style={{
							maxWidth: 'none',
						}}
						objectFit="cover"
						fluidImageData={graphicsByName.awards.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									"Partnering with FA has been incredible, no matter the
									challenge. They always deliver with care and with unparalleled
									creativity."
								</p>
								<footer>
									<p className="--small">
										<strong>Kenny Chui</strong>
										<br />
										Product Design Manager, Visual Systems
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									In the early phases of the Covid-19 pandemic, Facebook worked
									to combat misinformation and bring clear, objective
									information to its global user base. We built a kit of
									data-visualization components for use in the Covid-19
									Information Hub, that featured a range of chart and table
									styles.
								</p>
							</Text>
						</LayoutIndented>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="40rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_2UP}
						images={[
							graphicsByName.facebook_phones_03_01.childImageSharp.fluid,
							graphicsByName.facebook_phones_03_02.childImageSharp.fluid,
						]}
						chrome={false}
					/>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={graphicsByName.dataviz.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(FacebookPage, THEME_GREEN)
